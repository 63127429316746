.location {
  position: relative;
  .section__col-2 {
    padding-top: 73px;
    padding-bottom: 641px;
    position: relative;
    @include md {
      padding-top: 49px;
      padding-bottom: 534px; }
    @include xs {
      padding-top: 56px;
      position: static;
      padding-bottom: 220px; }
    @include r(375) {
      padding-bottom: 95px; } }
  .section__container {
    position: relative;
    z-index: 1;
    pointer-events: none; }
  &__title {
    margin-bottom: 21px;
    @include md {
      margin-bottom: 24px; } }
  &__text {
    margin-top: 17px;
    @include medium;
    @include md {
      font-size: 12px;
      margin-top: 19px;
      line-height: 1.67; } }
  &__map {
    @include coverdiv;
    @include xs {
      top: auto;
      bottom: 0;
      left: 0;
      height: 690px;
      &:after {
        content: none; } }
    @include r(375) {
      height: 490px; }

    .map {
      width: 100%;
      height: 100%;

      img {
        display: block;
        object-fit: none;
        width: 100%;
        height: 100%;
        object-position: center center;

        @include tablet {
          object-position: center; }

        @include md {
          object-position: center; }

        @include sm {
          object-position: center; }

        @include xs {
          object-position: 75% 100%; } } }

    &:after {
      content: '';
      opacity: 0.93;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 60%;
      pointer-events: none;
      background-image: linear-gradient(-180deg, #F5F7F9 9%, rgba(255,255,255,0.00) 100%); } }

  &__info {
    max-width: 1104px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    @include r(1300) {
      max-width: 900px; }
    @include tablet {
      max-width: 540px; }
    @include md {
      z-index: 2;
      left: 50%;
      transform: translateX(-50%); }
    @include xs {
      margin: 0 10px;
      position: relative;
      left: 0;
      width: auto;
      transform: translateY(91px); } }
  &__visual {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 49%;
    width: 100%;
    @include r(1300) {
      padding-top: 60%; }
    @include md {
      padding-top: 50%; } }
  &__label {
    position: absolute;
    max-width: 295px;
    top: -41px;
    right: -130px;
    @include md {
      max-width: 140px;
      top: -36px;
      right: -74px; }
    @include sm {
      right: -20px; }
    @include xs {
      right: -10px;
      top: -40px; }
    &:after {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url(../img/label-dots.svg);
      height: 63px;
      width: 63px;
      right: -37px;
      bottom: -38px;
      @include md {
        top: -200px;
        right: 250px; }
      @include xs {
        top: -110px;
        right: 80px; } } }
  &__form {
    position: absolute;
    left: 134px;
    bottom: -100px;
    max-width: 570px;
    width: 100%;
    z-index: 1;
    @include tablet {
      max-width: none;
      width: 100%;
      bottom: 0;
      transform: translateY(100%);
      left: 0; }
    @include xs {
      transform: none;
      position: relative; } } }

.llabel {
  z-index: 1;
  position: relative;
  padding: 30px 35px 30px 40px;
  background-color: $white;
  font-size: 20px;
  @include medium;
  line-height: 2;
  @include tablet {
    font-size: 16px;
    padding: 30px; }
  @include md {
    font-size: 10px;
    line-height: 1.8;
    padding: 13px 15px; }
  &__title {
    @include bold; }

  a {
    text-decoration: underline; }

  a:hover {
    text-decoration: none; } }

.sform {
  padding: 25px 40px 40px;
  background-color: $white;
  @include md {
    padding: 26px 25px 30px; }
  @include xs {
    padding: 15px 20px 20px; }
  &__title {
    font-size: 36px;
    @include semi;
    margin-bottom: 4px;
    @include md {
      font-size: 20px;
      margin-bottom: 14px; }
    @include xs {
      margin-bottom: 7px;
      margin-left: 10px; } }
  &__field {
    height: 60px;
    width: 100%;
    position: relative;
    @include xs {
      height: auto; } }
  &__input {
    width: 100%;
    height: 100%;
    font-size: 14px;
    border: 1px solid #D7D7D7;
    padding: 0 150px 0 20px;
    letter-spacing: 0.5px;
    @include xs {
      border-width: 0 0 1px 0;
      padding: 0 10px;
      text-align: center;
      height: 40px; } }
  &__btn {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    @include xs {
      display: flex;
      justify-content: center;
      position: static;
      transform: translateY(0);
      margin-top: 20px;
      top: 0; } }
  &__social {
    margin-bottom: 15px;
    @include md {
      margin-bottom: 13px; }
    @include xs {
      margin-bottom: 11px; } } }

.ssocial {
  display: flex;
  @include xs {
    flex-wrap: wrap; }
  &__item {

    &:not(:last-child) {
      margin-right: 15px;
      @include xs {
        margin-right: 0;
        margin-bottom: 10px; } } }
  &__link {
    display: flex;
    align-items: center;
    color: $themeColor;
    @include light;
    font-size: 14px;
    &:hover {
      .icon {
        transform: scale(1.1); } } }
  &__icon {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      transition: 0.3s;
      fill: $themeColor;
      font-size: 18px; } } }
