.random-party {
	padding: 60px 0 60px;
	background: url(../img/screens-done-op.svg) center center repeat;
	max-width: none;
	background-color: $themeColor;
	background-size: 1800px;
	text-align: center;
	color: $white;

	.container {}

	.section {

		&__title {
			@include bold;
			font-size: 64px;
			line-height: 1.4;
			margin: 38px auto 28px;
			max-width: 874px;
			text-align: center;

			@include md {
				font-size: 32px;
				margin-top: 0;
				margin-bottom: 20px; }

			@include xs {
				font-size: 28px; } } }

	@include laptop {
		padding: 50px 0 50px;
		background-size: 1200px; }
	@include md {
		padding: 80px 0 80px; }
	@include xs {
		background-size: 900px; }
	.section__title {
		letter-spacing: 1.3px;
		margin-bottom: 55px;
		@include tablet {
			margin-bottom: 37px; } }
	.container {
		@include xs {
			padding: 0 16px; } }
	&__inner {
		padding: 82px 20px 100px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include tablet {
			padding: 40px 20px; }
		@include md {
			padding: 0 20px;
			border: none; } }

	&__text {
		line-height: 1.6;
		letter-spacing: 0.003em;
		max-width: 540px;
		text-align: center;
		font-size: 18px;
		margin-bottom: 42px;
		@include tablet {
			font-size: 16px;
			margin-bottom: 38px; } }

	&__btn {
		max-width: 368px;
		width: 100%;
		margin: 0 auto; } }
