.cards {
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	padding-top: 56.25%;
	&__slider {
		@include coverdiv;
		.slick-list, .slick-slide, .slick-track {
			height: 100%; } } }

.card {
	overflow: hidden;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: space-between;
	&__speaker-photo {
		display: flex;
		align-items: flex-end;
		width: 42.9%;
		img {
			width: 85.6%; } }
	&__content {
		width: 57.1%;
		color: $white;
		font-size: 3.1vw;
		margin-top: 5.8%; }
	&__theme {
		font-size: 1.7em;
		@include extraBold;
		width: 90%;
		line-height: 1.2;
		margin-bottom: 2.1vw; }
	&__speaker-name {
		font-size: 1.35em;
		line-height: 1.2;
		margin-bottom: 1.2vw; }
	&__speaker-position {
		font-size: 0.84em;
		line-height: 1.2;
		@include medium; } }


