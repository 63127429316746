
.sec-faq .section__container {
  padding-bottom: 50px; }
.faq {
  padding: 0;
  margin: 0 auto;
  list-style: none;
  max-width: 970px;
  background: #fff;

  &.marged {
    margin-top: 52px; }

  &__section-title {
    font-size: 24px;
    line-height: 1.36;
    margin: 40px 0 20px;
    font-weight: 700; }
  &__list {
    @include sm {
      margin: 20px -15px; } }
  &__item {
    position: relative;
    border: 2px solid #230cea;
    margin: 0 0 -2px;

    &.open {
      .faq__question {
        &:after {
          transform: rotate(-135deg);
          margin-top: -5px; } } } }

  &__question {
    font-size: 20px;
    line-height: 1.36;
    cursor: pointer;
    font-weight: 700;
    padding: 20px 70px 20px 30px;
    width: 100%;
    border: none;
    text-align: left;
    background: transparent;
    position: relative;
    @include sm {
      font-size: 18px;
      cursor: pointer;
      padding: 15px 50px 15px 20px; }
    @include xs {
      font-size: 16px; }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 30px;
      margin-top: -8px;
      height: 12px;
      width: 12px;
      border-bottom: 2px solid #000;
      border-right: 2px solid #000;
      transform: rotate(45deg);
      @include sm {
        right: 20px; } } }
  &__ansver {
    font-size: 16px;
    line-height: 1.5;
    display: none;
    padding: 0 30px 25px;
    max-width: 840px;
    @include sm {
      font-size: 14px;
      padding: 0 20px 15px; }
    @include xs {
      font-size: 12px; }
    a {
      color: #230cea; }

    p {
      margin-bottom: 15px;

      &:last-child {
        margin: 0; } }

    ul, ol {
      line-height: 2;
      margin-bottom: 15px; }

    ol {
      list-style-type: decimal;
      padding-left: 20px; }
    ul {
      li {
        position: relative;
        padding-left: 1.2em;
        &:before {
          content: '';
          position: absolute;
          top: 0.8em;
          left: 0;
          height: 0.3em;
          width: 0.3em;
          border-radius: 50%;
          background-color: $textColor; }
        &:has(ul), &:has(ol) {
          &:before {
            display: none; } } } } } }
