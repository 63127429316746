.text-lg {
  font-size: 20px;
  @include medium;
  line-height: 2;
  @include md {
    font-size: 14px;
    line-height: 1.79; }
  a {
    text-decoration: underline; }
  p + p {
    margin-top: 40px;
    @include md {
      margin-top: 29px; } }
  & + .text {
    margin-top: 40px;
    @include md {
      margin-top: 25px; }
    @include xs {
      margin-top: 19px; } }
  & + .text-l {
    margin-top: 30px;
    @include sm {
      margin-top: 20px; } } }

.text-l {
  line-height: 1.68;
  font-size: 18px;
  @include sm {
    font-size: 12px; }
  p {
    margin-bottom: 20px;
    @include sm {
      margin-bottom: 15px; }
    &:last-child {
      margin-bottom: 0; } }

  a {
    color: $linkColor;
    text-decoration: underline;
    transition: .2s ease;
    &:hover {
      color: #023753; } }

  ul, ol {
    line-height: 2;
    margin-bottom: 20px;

    @include sm {
      margin-bottom: 15px; } }

  ol {
    list-style-type: decimal;
    padding-left: 20px; }

  ul {
    li {
      position: relative;
      padding-left: 1.2em;
      &:before {
        content: '';
        position: absolute;
        top: 0.8em;
        left: 0;
        height: 0.3em;
        width: 0.3em;
        border-radius: 50%;
        background-color: $textColor;
        &:has(ul), &:has(ol) {
          &:before {
            display: none; } } } } } }

.text {
  p {
    & + p {
      margin-top: 20px;
      @include md {
        font-size: 10px;
        line-height: 2; } } }
  a {
    color: $linkColor;
    text-decoration: underline; } }

.workshop {
  .section__col-2 {
    padding: 150px 0 144px;
    @include md {
      padding: 100px 0 85px; }
    @include xs {
      padding: 100px 0 85px; } }
  &__title {
    margin-bottom: 41px;
    @include md {
      margin-bottom: 27px; } }
  &__btn {
    margin-top: 40px; }
  &__link {
    margin-top: 40px;
    font-size: 26px;
    color: $white;
    @include md {
      font-size: 20px;
      line-height: 1.2;
      margin-top: 30px; }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none; } } } }

.article {
  z-index: 1;
  position: relative;
  padding: 80px 0 20px;
  @include sm {
    padding: 40px 0 20px; }
  a {
    color: $linkColor;
    text-decoration: underline; }
  h2 {
    font-size: 36px;
    line-height: 1.28;
    @include semi;
    margin-bottom: 40px;
    @include sm {
      font-size: 28px;
      @include bold;
      margin-bottom: 20px; }

    p {
      font-size: inherit;
      margin-bottom: 0; } }
  h3 {
    font-size: 26px;
    line-height: 1.28;
    @include bold;
    margin-bottom: 20px;
    @include sm {
      font-size: 24px; } }
  h2, h3 {
    position: relative;
    display: inline-block;
    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      background-image: url("../img/h-dots.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 23px;
      width: 103px;
      right: -14px;
      top: 50%;
      transform: translate(100%, -50%);
      @include sm {
        background-image: url("../img/dots-i-mob.svg");
        right: auto;
        left: -15px;
        transform: none;
        top: -20px;
        height: 32px;
        width: 32px; } } }
  .markdown-sm {
    p {
      font-size: 16px !important; } }
  div {
    .paragraph-sm {
      p {
        font-size: 16px; } }
    .paragraph {
      p {
        font-size: 20px;
        margin-bottom: 40px;
        line-height: 2;
        @include medium;
        @include sm {
          margin-bottom: 20px; } } } }
  p {
    font-size: 20px;
    margin-bottom: 40px;
    line-height: 2;
    @include sm {
      margin-bottom: 20px; } }
  b {
    @include bold; }
  i {
    @include italic; }
  .center-align {
    text-align: center; }
  .paragraph {
    @include medium;

    &.name {
      margin-top: 12px;
      @extend .markdown-sm;
      font-size: 20px;
      line-height: 1.68; } }

  .paragraph-sm {
    font-size: 16px; }
  ul, ol {
    line-height: 2;
    margin-bottom: 40px;
    font-size: 20px;
    @include sm {
      margin-bottom: 20px;
      font-size: 16px; } }
  ol {
    list-style-type: decimal;
    padding-left: 20px; }
  ul {
    li {
      position: relative;
      padding-left: 25px;
      &:before {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: $textColor; } } }
  blockquote {
    margin-bottom: 40px;
    font-size: 26px;
    line-height: 1.5;
    @include mediumItalic;
    @include sm {
      margin-bottom: 20px; } }
  &__row {
    display: flex;
    margin: 0 -15px;
    @include sm {
      margin: 0;
      display: block; }
    p {
      margin-bottom: 0;
      & + p {
        margin-top: 40px; } }
    & + .article__img {
      margin-top: 40px;
      @include sm {
        margin-top: 10px; } }
    & + & {
      margin-top: 40px; } }
  &__col {
    width: 50%;
    padding: 0 15px;
    @include sm {
      padding: 0;
      margin-bottom: 10px;
      width: 100%; } }
  &__img {
    position: relative;
    & + p {
      margin-top: 40px; }

    img {
      width: 100%;
      max-width: 460px; } }

  &__left {
    width: 140px;
    position: absolute;
    left: -61px;
    transform: translateX(-100%);
    top: 80px;
    color: $themeColor;
    @include tablet {
      display: none; }
    p {
      font-size: 16px;
      margin-bottom: 15px;
      line-height: 1.6; }
    &:after {
      content: '';
      position: absolute;
      background-image: url("../img/dots_white.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 103px;
      width: 23px;
      left: -28px;
      top: 0;
      transform: translateX(-100%); } } }

.video-wrapper {
  position: relative;
  padding-top: 55%;
  width: 100%;
  iframe {
    @include coverdiv; } }

.react-jobs {
  .article__img {
    width: 80%;
    @include md {
      width: 100%; } }
  .section__img {
    width: 80%;
    @include md {
      width: 100%; } } }

#popup-ticket {
  .pop-ticket {

    &__btn {
      color: $black;
      background: $yellow;
      border: 2px solid transparent;
      box-shadow: none;

      &:hover {
        background: $white; }

      &:after,
      &:before {
        display: none; } } } }
