.btn {
  min-width: 122px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $heroBtnColor;
  @include semi;
  font-size: 14px;
  height: 44px;
  padding: 0 22px;
  line-height: 1.4;
  text-transform: uppercase;
  color: $textColor;
  position: relative;
  transition: 0.3s;
  box-shadow: 4px 4px 0 0 #023753;
  transition: all ease 0.2s;
  text-decoration: none;

  &:hover, &:focus-visible {
    background: #FFEA4A;
    box-shadow: 2px 2px 0 0 #023753; }
  &:focus-visible {
    outline-width: 3px !important; }

  &--hero {
    padding: 0 22px;
    height: 52px;
    display: flex;
    @include md {
      padding: 0 16px;
      height: 44px; } }

  &--huge {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    font-size: clamp(16px, 5vw, 82px);
    margin: 0 auto;
    padding: clamp(15px, 5vw, 30px) clamp(40px, 5vw, 80px);

    .icon {
      &-arrow_r {
        width: clamp(16px, 8vw, 120px);
        height: clamp(20px, 7vw, 64px);
        margin: auto 0 auto clamp(20px, 5vw, 60px);
        fill: $textColor; } } }

  &--warning {
    background: #fff;
    &:hover, &:focus-visible {
      background: $heroBtnColor; } }

  &--secondary {
    background: #230CEA;
    color: #fff;
    &:hover, &:focus-visible {
      background: #2647ff;
      color: #fff; } }

  &--under {
    background: transparent !important;
    box-shadow: none !important;
    text-decoration: underline;
    color: $heroBtnColor;
    padding: 0;
    min-width: auto;
    &:hover {
      text-decoration: none; } }
  &--block {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }
  &--lg {
    height: 64px; } }

.bbtn {
  font-size: 12px;
  @include semi;
  text-transform: uppercase;
  padding: 8px 29px;
  line-height: 1.67;
  display: inline-block;
  background-color: $themeColor;
  text-align: center;
  color: $white;
  transition: 0.3s;
  border: 2px solid transparent;
  white-space: nowrap;
  @include xs {
    font-size: 14px;
    padding: 11px 61px 12px; }
  &:hover, &:focus-visible {
    background-color: white;
    color: $themeColor;
    border-color: $themeColor; }
  &--lg {
    padding: 18px 56px;
    @include xs {
      font-size: 12px;
      padding: 18px 57px; } } }

.is-hidden {
  display: none; }
