.talks {
  .section__col-2 {
    background: $themeColor;
    padding: 100px 0 317px;
    @include md {
      padding: 269px 0 411px; }
    @include xs {
      padding: 55px 0 415px; } }
  &__title {
    color: $white;
    @include xs {
      margin-left: -80px; } }
  &__content {
    max-width: 1170px;
    margin: -280px auto -183px;
    padding: 0 0 0 200px;
    position: relative;
    z-index: 1;
    @include tablet {
      max-width: 970px; }
    @include md {
      max-width: 540px;
      padding: 0;
      margin: -380px auto -183px; }
    @include xs {
      max-width: none;
      margin: -380px 0 -183px 20px; } }
  &__container {
    padding: 83px 200px 72px 200px;
    background-color: $white;
    @include tablet {
      padding: 80px 100px; }
    @include md {
      padding: 33px 20px; } }
  &__item {
    &:not(:last-child) {
      margin-bottom: 33px;
      @include md {
        margin-bottom: 19px; } } }
  &--schedule {
    .talks__content {
      max-width: 1200px;
      margin: -280px auto 0;
      padding: 0 40px;
      @include md {
        margin: -370px auto 0;
        padding: 0 20px; } }
    .talks__container {
      padding: 60px 100px;
      @include tablet {
        padding: 60px 80px; }
      @include md {
        padding: 40px 60px; }
      @include xs {
        padding: 15px 20px; } } } }

.titem {
  &.is-active {
    margin: 57px 0;
    @include tablet {
      margin: 41px 0; }
    .titem__title {
      font-size: 36px;
      text-decoration: none;
      line-height: 1.67;
      margin-bottom: 13px;
      margin-right: -5px;
      @include md {
        font-size: 20px; }
      @include xs {
        line-height: 1.3; } } }
  &__title {
    display: inline-block;
    @include medium;
    font-size: 20px;
    line-height: 1.5;
    text-decoration: underline;
    text-decoration-color: rgba(2, 55, 83, 0.5);
    @include md {
      font-size: 15px; } }
  &__content {
    margin-bottom: 20px;
    display: none;
    @include xs {
      margin-bottom: 50px; } }
  &__name {
    @include medium;
    @include md {
      font-size: 14px; } }
  &__place {
    opacity: 0.5;
    margin-bottom: 23px;
    @include md {
      font-size: 10px;
      margin-bottom: 19px; } }
  &__text {
    margin-bottom: 23px;
    line-height: 1.5;
    @include md {
      font-size: 14px;
      line-height: 1.8;
      max-width: 260px; } }
  &__label {
    color: $white;
    @include semi;
    font-size: 12px;
    border-radius: 4px;
    display: inline-block;
    padding: 0 9px;
    background-color: $themeColor;
    &--red {
      background-color: $darcRed; }
    &--lred {
      background-color: $lRed; }
    &--green {
      background-color: $green; }
    &--violet {
      background-color: $violet; }
    &--lgreen {
      background-color: $lgreen; }
    &--yellow {
      background-color: $yellow; } } }
