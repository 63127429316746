.s-teams-2 {
  .section__col-4 {
    padding-top: clamp(60px, 10vw, 80px); } }
.teams-body {
  padding: 14px 0 80px;
  &__cta {
    margin-top: 20px; }
  h2 {
    font-size: 36px;
    line-height: 1.28;
    @include semi;
    margin-bottom: 40px;
    @include sm {
      font-size: 28px;
      @include bold;
      margin-bottom: 20px; }

    p {
      font-size: inherit;
      margin-bottom: 0; } }
  h3 {
    font-size: 26px;
    line-height: 1.28;
    margin-bottom: 20px;
    @include bold;

    @include sm {
      font-size: 24px; } }
  h2,h3 {
    position: relative;
    display: inline-block;
    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      background-image: url("../img/h-dots.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 23px;
      width: 103px;
      right: -14px;
      top: 50%;
      transform: translate(100%, -50%);
      @include sm {
        background-image: url("../img/dots-i-mob.svg");
        right: auto;
        left: -15px;
        transform: none;
        top: -20px;
        height: 32px;
        width: 32px; } } }
  ul,ol {
    font-size: clamp(14px, 2vw, 20px);
    line-height: 2;
    margin: 24px 0;
    padding-left: 20px;
    list-style: none;

    li {
      position: relative;
      padding-left: clamp(20px, 2vw, 30px); } }

  ul {
    li {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 15px;
        width: 8px;
        height: 8px;
        background: $themeColor;
        border-radius: 50%;
        @include sm {
          top: 10px; } } } }

  ol {
    counter-reset: section;
    padding: 0;

    li {
      &:before {
        color: $themeColor;
        display: inline-block;
        font-weight: 700;
        min-width: 20px;
        counter-increment: section;
        content: counters(section,".") " "; } } }

  p {
    font-size: clamp(14px, 2vw, 20px);
    margin-bottom: 40px;
    line-height: 2;
    @include sm {
      margin-bottom: 20px; } }

  a:not(.btn) {
    color: $themeColor;
    text-decoration: underline;

    &:hover {
      text-decoration: none; } } }
