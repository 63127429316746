.s-multipass {
  position: relative;
  padding: 172px 0;
  background: $themeColor;
  color: $white;
  max-width: none;
  overflow: hidden;

  @include tablet {
    padding: 100px 0; }

  @include sm {
    padding: 70px 0; }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 760px;
    width: 100%;
    bottom: 0;
    background: url(../img/multipass_bg.svg) center bottom no-repeat;
    background-size: contain;

    @include sm {
      height: 563px; } }

  .container {
    position: relative;
    z-index: 1; }

  .section {

    &__title {
      font-size: 64px;
      @include bold;
      line-height: 1.4;
      margin: 0 0 38px;
      max-width: 874px;

      @include md {
        font-size: 32px;
        margin-top: 0;
        margin-bottom: 25px; }
      @include sm {
        text-align: center; }

      @include xs {
        font-size: 28px; } } } }

.multipass {
  display: flex;
  flex-direction: column;
  margin: 0;

  &__left {
    display: flex;
    padding: 0 20px 0 0px;
    align-items: center;
    margin: 0 0 60px;
    @include tablet {
      width: 100%;
      align-items: flex-start;
      margin: 0 0 30px;
      padding: 0; }

    @include sm {
      justify-content: center; }

    a {
      @extend .btn;
      align-self: flex-start;
      margin: 10px 0 0;
      @include sm {
        margin: 30px auto 10px; } } }

  &__right {
    display: flex;
    align-items: center;
    @include tablet {
      width: 100%; }
    @include sm {
      flex-direction: column; } }

  &__desc {
    display: flex;
    flex-wrap: wrap;
    @include sm {
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center; } }

  &__text {
    line-height: 1.5;
    font-size: 22px;
    margin: 0;
    max-width: 380px;
    font-weight: 600;

    strong {
      color: $heroBtnColor;
      font-weight: 700; } }

  &__pass {
    background: #fffc00;
    color: #000; }

  .icon-equal {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    fill: #fff;
    margin: 0 30px 0 40px;

    @include sm {
      margin: 20px 0 10px; } } }

.multipass-list {
  width: 100%;
  padding: 2px;
  a {
    @include medium;
    font-size: 16px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    color: $heroBtnColor;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all ease .2s;

    &:hover, &:focus-visible {
      text-decoration: underline;
      opacity: 1;
      & ~ picture img {

        opacity: 0.3; } } }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    height: 100px;

    img {
      display: block;
      max-width: 94%;
      height: auto;
      margin: auto;
      opacity: 1;
      transition: all ease .2s; }

    .icon-plus {
      position: absolute;
      left: -28px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      content: '';
      width: 24px;
      height: 24px;
      opacity: 1;
      fill: #fff;
      transition: all ease .2s; }

    &.swiper-slide {
      visibility: hidden; }
    &.swiper-slide-next,
    &.swiper-slide-prev,
    &.swiper-slide-next ~ .swiper-slide,
    &.swiper-slide-active {
      visibility: visible; }
    &.swiper-slide-active {
      .icon-plus {
        opacity: 0; } } } }

.visa-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 158px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background: $heroBtnColor;
  padding: 0 22px;
  margin: 0;
  color: #000;
  text-align: left;

  @include sm {
    width: 250px; }

  &__img {
    margin: 0 14px 0 0;

    .icon-face {
      width: 38px;
      height: 48px;
      color: #000; } }

  &__desc {

    div:first-child {
      @include bold;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 1.2;
      margin: 0 0 10px; }

    div:last-child {
      font-size: 10px;
      margin: 0;
      line-height: 1.36; } } }
