.s-full-ticket {

  .section {

    &__col-2 {
      padding: 100px 0 100px;
      @include md {
        padding: 90px 0 80px; }
      @include xs {
        padding: 80px 0 60px; } }

    &__title {
      margin-bottom: 0;
      @include md {
        margin-bottom: 0; } } } }

.full-ticket {
  display: flex;
  margin: 80px 0 50px -200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  flex-wrap: wrap;

  @include md {
    grid-template-columns: repeat(2, 1fr);
    margin: 60px -20px 50px -200px;
    flex-wrap: wrap;
    gap: 10px; }

  @include sm;

  @include xs {
    margin: 50px -10px 50px -120px; }

  @include xxs {
    grid-template-columns: repeat(1, 1fr);
    margin: 50px -10px 50px -90px;
    gap: 20px; }

  &__item {
    position: relative;
    display: flex;
    flex: 1 0;
    flex-direction: column;
    font-size: 16px;
    line-height: 1.28;
    padding: 40px 30px;
    min-height: 250px;
    width: 100%;
    color: $black;
    background: $white;

    &.full-width {
      min-width: 100%;
      grid-area: 1 / 1 / 2 / 3;

      @include xxs {
        grid-area: auto;
        width: 300px;
        min-width: 300px; } }

    @include md {
      padding: 15px;
      min-height: 250px;
      min-width: calc(50% - 10px); }

    @include sm {
      margin: 0 auto;
      flex: 1 1 calc(50% - 10px);
      min-height: 250px; }

    @include xxs {
      width: 300px; }

    &:hover {
      .full-ticket {
        &__bg {
          opacity: 0.2; } } } }

  a.full-ticket__item {
    text-decoration: underline;

    &:hover {
      text-decoration: none; } }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-size: cover;
    opacity: 0;
    transition: all ease 0.1s; }

  &__title {
    position: relative;
    @include bold;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px 0 20px;
    min-height: 48px;
    color: $themeColor;
    z-index: 1;
    order: 1;

    @include md {
      font-size: 18px; } }

  &__img-wrap {
    position: relative;
    width: 100%;
    height: 64px;
    margin: 0 auto;
    z-index: 1;
    order: 0;

    @include md {
      margin-top: 20px; } }

  &__img {
    display: block;
    height: 100%;
    width: auto;
    margin: 0 auto; }

  &__desc {
    position: relative;
    display: flex;
    font-size: 14px;
    line-height: 1.42;
    align-items: center;
    justify-content: center;
    margin: auto auto 0;
    text-align: center;
    min-height: 40px;
    max-width: 320px;
    z-index: 1;
    order: 1;

    @include md {
      min-height: 60px;
      font-size: 12px; } }

  .btn {
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto; } }
