.speakers {
  padding: 0 0 66px;
  position: relative;
  @include xs {
    padding: 0 0 22px; }
  &:before, &:after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
    @include md {
      display: none; } }
  &:before {
    top: 40%;
    right: -50px;
    width: 203px;
    height: 223px;
    background-image: url(../img/speakers-dots.svg); }
  &:after {
    top: 60%;
    left: 41px;
    width: 183px;
    height: 183px;
    background-image: url(../img/speakers-dots1.svg); }
  .section__col-2 {
    padding: 100px 0 210px;
    @include md {
      padding: 60px 0 125px; }
    @include xs {
      padding: 40px 0 72px; } }
  &__title {
    margin-bottom: 37px;
    @include md {
      margin-bottom: 25px; }
    &--mc {
      margin-left: 110px;
      margin-bottom: 3px;
      @include md {
        margin-left: 94px;
        margin-bottom: 84px; }
      @include xs {
        margin-left: -11px;
        margin-bottom: 55px; } } }
  &__list {
    display: flex;
    margin: -150px -15px 136px;
    flex-wrap: wrap;
    @include md {
      margin: -81px -15px 106px; }
    @include xs {
      margin: -45px -15px 66px; }
    &--mc {
      margin-top: 0; } }

  &__item {
    width: 33.33%;
    padding: 10px 15px;
    position: relative;
    @include md {
      width: 50%; }
    @include sm {
      padding: 5px; }
    @include xs {
      width: 100%; }
    &:nth-child(3n-1) {
      top: 45px;
      @include md {
        top: 0; } }
    &:nth-child(3n-2) {
      top: 90px;
      @include md {
        top: 0; } }
    &:nth-child(2n-1) {
      @include md {
        top: 50px; }
      @include xs {
        top: 0; } }

    &_cfp {
      .speaker {
        &__company {
          font-size: 16px;
          margin: 8px 0 24px; } } } } }

.random-party + .mcs {
  padding-top: 100px;

  @include md {
    padding-top: 80px; } }

.speaker {
  background-color: $mainBg;
  border-style: solid;
  border-color: $mainBg;
  padding: 20px 21px 16px 20px;
  transition: 0.3s;
  position: relative;
  &[onclick] {
    cursor: pointer; }
  @include tablet {
    padding: 20px; }
  @include md {
    padding: 14px; }

  &:hover {
    background-color: $white; }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    &:hover, &:focus-visible {
      opacity: 1; } }
  &__header {
    display: flex;
    margin-bottom: 13px; }
  &__info {
    flex-grow: 1; }
  &__addition {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 14px;
    gap: 5px 0; }
  &__label {
    white-space: nowrap;
    border-radius: 4px;
    font-size: 12px;
    color: $white;
    background: var(--tagBG);
    @include semi;
    line-height: 1.8;
    padding: 0 11px;
    @include tablet {
      font-size: 10px;
      padding: 0 7px; } }
  &__socials {
    display: flex;
    position: relative;
    z-index: 2; }
  &__social {
    a {
      display: flex;
      height: 24px;
      width: 24px;
      align-items: center;
      justify-content: center;
      &:hover {
        .icon {
          fill: $themeColor;
          opacity: 1; } } }
    .icon {
      transition: 0.3s;
      font-size: 18px;
      fill: $textColor;
      opacity: 0.35;
      @include tablet {
        font-size: 14px; } }
    &:not(:last-child) {
      margin-right: 5px; } }
  &__name {
    font-size: 24px;
    @include semi;
    line-height: 1.2;
    margin-bottom: 2px;
    transition: 0.3s;
    &--link {
      &:hover {
        color: $linkColor; } }
    @include tablet {
      font-size: 20px;
      line-height: 1.1; } }
  &__company {
    line-height: 1.4;
    color: $gray;
    font-size: 12px;
    display: flex;
    gap: 4px 8px;
    align-items: center;
    a {
      text-decoration: underline;
      color: $linkColor;
      &:hover {
        text-decoration: none; } }
    @include tablet {
      font-size: 10px; } }

  &__talk {
    line-height: 1.67;
    font-size: 12px;
    margin: 0 0 8px;

    a {
      color: inherit;
      position: relative;
      z-index: 2;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } }

    span {
      font-weight: 600; } }

  &__text {
    line-height: 1.67;
    font-size: 12px;
    &:not(:has( > p)) {
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical; }
    p {
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;

      &:not(:first-child) {
        display: none; } }

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } } }

  &__avatar {
    height: 140px;
    min-width: 140px;
    width: 140px;
    overflow: hidden;
    margin-right: 15px;
    position: relative;
    @include tablet {
      height: 120px;
      width: 120px;
      min-width: 120px; }
    @include md {
      margin-right: 20px; }

    // &:after
    //   transition: 0.3s
    //   content: ''
    //   position: absolute
    //   top: 0
    //   left: 0
    //   width: 100%
    //   height: 100%
    //   opacity: 0.4
    //   background: var(--tagBG)

    img {
      height: 100%;
      width: 100%;
      object-fit: cover; } }

  &--fun {
    .speaker__header {
      align-items: center; }
    .speaker__info {
      font-size: 24px; }
    .speaker__text {
      font-size: 14px; }

    .socials {
      margin: 10px 0 0 0; }
    .icon {
      transform: scale(1.5);
      fill: #023753;

      &:hover {
        fill: #230cea; } } }

  .btn {
    color: $textColor;
    text-decoration: none; } }
