.diversity {

	.section__col-2 {
		padding: 100px 0 0;
		position: relative;
		z-index: 1;
		@include md {
			padding: 55px 0 0; } }

	&__title {
		margin-bottom: 43px;
		@include md {
			margin-left: -94px;
			margin-bottom: 35px;
			.section-title {
				font-size: 28px;
				line-height: 1.1; } }
		@include xs {
			margin-bottom: 27px; } }

	&__partners {
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin: 80px auto 50px;
		max-width: 75%;
		@include md {
			max-width: 100%; } }

	&__partner {
		width: 400px;
		max-width: 40%;
		display: block; }

	&__progress-title {
		text-align: center;
		font-size: 18px;
		line-height: 1.5;
		margin: 30px 0 30px;
		@include xs {
			font-size: 16px; } }

	&__progress-bar {
		width: 70%;
		margin: 30px auto 50px;
		height: 6px;
		background: #fff;
		@include md {
			width: 80%;
			height: 5px; }
		@include xs {
			width: 90%; } }

	&__progress {
		background: #230cea;
		height: 6px;
		@include md {
			height: 5px; } }

	&__buttons {
		justify-content: center;
		display: flex;
		flex-wrap: wrap;
		gap: 10px 20px;
		margin-bottom: 100px; }


	img {
		width: 100%; } }
