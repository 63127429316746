.video {
  margin-top: -60px;
  margin-bottom: -5px;
  @include md {
    margin-top: 0;
    margin-bottom: -10px; }
  @include xs {
    margin-bottom: -10px; }
  &__wrapper {
    position: relative;
    padding-top: 76%;
    max-width: 858px;
    margin-left: -200px;

    @include md {
      transform: translateY(-20px);
      margin-left: -190px;
      padding-top: 92.5%; }
    @include xs {
      margin-left: -80px;
      transform: translateY(-20px);
      padding-top: 84.5%; }
    &:before {
      content: '';
      position: absolute;
      top: -30px;
      left: 62px;
      background-image: url(../img/video-dots1.svg);
      height: 103px;
      width: 23px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: -1;
      @include md {
        display: none; } }
    &:after {
      content: '';
      position: absolute;
      bottom: 9px;
      right: -21px;
      background-image: url(../img/video-dots.svg);
      height: 203px;
      width: 124px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: -1;
      @include md {
        bottom: -48px;
        right: -47px;
        width: 81px;
        height: 132px; } }
    iframe {
      @include coverdiv; } } }
