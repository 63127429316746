
.s-prices {
  position: relative;
  margin: 0;
  max-width: none;
  .section__title {
    margin-left: 0;
    margin-bottom: 40px;
    max-width: none;
    text-transform: uppercase;
    @include bold;
    font-size: 64px;
    line-height: 1.2; } }

.prices-item {
  position: relative;
  z-index: 1;
  &__content {
    li {
      line-height: 1.3; } }
  &__price-value {
    strong {
      font-size: 64px;
      font-family: var(--ff-msw);
      font-weight: 600;
      @include sm {
        font-size: 48px; } } }

  &__toggle {
    --accentColor: #fff; }

  &__close {
    .icon {
      stroke: #fff !important; } }
  &__title {
    font-family: Poppins-Bold,Arial,sans-serif; } }

.prices-swiper {
  .swiper-slide {
    width: 374px;
    @include md {
      width: 360px; }
    @include sm {
      width: 302px; }
    @include xxxs {
      width: 280px; } }
  .swiper-container {
    @include r(1275) {
      overflow: visible; } }
  .swiper-pagination-bullet {
    border-color: #FFD121 !important;
    &-active {
      background: #FFD121 !important; } }
  &__next {
    right: 0; }
  &__prev {
    left: 0; } }
