.s-cta {
  padding: clamp(50px, 9vw, 110px) 0;
  background: #230CEA;
  color: #fff;
  max-width: 100%;
  text-align: center; }

.cta {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  &__desc {

    font-size: clamp(22px, 2vw, 26px);
    line-height: 1.28;
    margin-bottom: 30px;
    font-family: Poppins-Bold, Arial, sans-serif;
    font-weight: 700; }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem; } }
