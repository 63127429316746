
@mixin GPFont {
  font-family: GothamPro, sans-serif; }

.sec-checkout {
  padding: 80px 0;
  max-width: none;
  background: var(--checkout-section-bg);
  font-family: var(--checkout-section-ff, GothamPro), sans-serif;
  color: var(--checkout-section-color);
  @include sm {
    padding: 60px 0; }

  &__title {
    margin: 0 auto 22px;
    letter-spacing: 0.01em;
    text-align: center;
    font-size: clamp(30px, 7vw, 48px);
    font-weight: 900;
    line-height: 1.0;
    text-transform: uppercase;
    @include sm {
      margin-bottom: 30px;
      margin-top: 0; } }
  &__description {
    margin-bottom: clamp(25px, 10vw, 40px);
    line-height: 1.6;
    font-size: clamp(12px, 2vw, 16px);
    text-align: center; }
  &__review {
    margin-bottom: 60px;
    @include sm {
      margin-bottom: 40px; } }
  .container {
    max-width: 1140px;
    @include md {
      padding-left: 20px;
      padding-right: 20px; } } }

.checkout-tabs {
  display: flex;
  position: relative;
  margin-bottom: 25px;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0px;
    display: block;
    border-bottom: 2px solid #eee; }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 15px 28px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    z-index: 4;
    font-family: inherit;
    font-size: 18px;
    font-weight: 700;
    color: var(--checkout-card-text-color, #fff);
    background: transparent;
    border: none;
    @include sm {
      padding: 10px 5px;
      font-size: 14px;
      flex: 1; }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 2px; }
    &.is-active {
      &:after {
        background: var(--accentColor); } } } }

.tito-wrapper {
  max-width: 900px;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  @include md {
    max-width: none; }
  @include sm {
    margin-top: 40px; }

  &__text {
    margin-top: 30px;
    line-height: 1.6;
    font-size: clamp(12px, 2vw, 16px);
    text-align: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none; } } } }

.tito-widget {
  margin-top: 0;
  margin-bottom: 0;
  font-family: inherit;
  color: var(--checkout-card-text-color, #fff);

  .tito-widget-form {
    max-width: none;
    background: transparent;
    border: none;
    padding: 0;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 15px; }

    .tito-release {
      display: grid;
      gap: 35px 40px;
      grid-template-columns: 1fr 168px;
      grid-template-rows: 1fr 1fr;
      padding: var(--checkout-card-padding, 40px);
      border-radius: var(--checkout-card-radius, 10px);
      position: relative;
      z-index: 1;
      background: var(--checkout-card-bg);
      border: var(--checkout-card-border) !important;
      @include sm {
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr auto;
        margin-top: 0;
        gap: 40px 0;
        padding: 30px; }
      @include xxxs {
        padding: 25px;
        gap: 25px 0; }
      &:has(.tito-release--title > small) {
        @include rmin(768) {
          grid-template-rows: 28px 1fr auto;
          .tito-release-description {
            grid-row: 1 / 4; }
          .tito-price-details-regular {
            grid-row: 2 / 3;
            justify-content: center !important; }
          .tito-quantity, .tito-release--status {
            grid-row: 3 / 4; } }
        @include sm {
          grid-template-rows: auto 5px 1fr;
          .tito-price-details-regular, .tito-quantity {
            grid-row: 3/4; } }
        @include xxxs {
          grid-template-rows: auto 28px 1fr; } }
      &:first-child {
        margin-top: 0; }
      .tito-release--title {
        font-size: 20px;
        color: var(--checkout-card-text-color, #fff);
        font-weight: var(--checkout-card-title-weight, 700);
        line-height: 1.3;
        text-transform: uppercase;
        margin-bottom: 25px;
        @include xs {
          font-size: 16px; }
        @include xxxs {
          margin-bottom: 15px; }
        small {
          background: var(--cehckout-badge-bg);
          border-radius: 5px;
          padding: 8px 5px;
          color: var(--cehckout-badge-color);
          font-weight: 500;
          position: absolute;
          width: 168px;
          text-align: center;
          top: 40px;
          right: 40px;
          text-transform: uppercase;
          font-size: 11px;
          line-height: 1;
          @include sm {
            top: auto;
            bottom: 95px;
            right: 30px;
            width: calc(100% - 60px); }
          @include xxxs {
            width: calc(100% - 50px);
            right: 25px;
            bottom: 90px; } } }

      .tito-release-description {
        padding: 0 40px 0 0;
        grid-row: 1 / 3;
        align-items: start;
        flex-direction: column;
        border-right: var(--checkout-card-border);
        @include sm {
          min-width: 100%;
          grid-row: 1/2;
          grid-column: 1/3;
          padding-right: 0;
          border: none; }

        & > * {
          flex-basis: auto !important; }

        .tito-release-description--description {
          color: var(--checkout-card-text-color) !important;
          font-size: 12px;

          a {
            color: inherit;
            text-decoration: underline; }
          p {
            color: inherit;
            font-size: 14px;
            @include xxxs {
              font-size: 12px; }
            &:empty {
              display: none; } } } }

      .tito-quantity {
        align-self: start;
        position: relative;
        align-items: stretch;
        width: 100%;
        border-radius: 100px;
        gap: 5px;
        border: var(--checkout-quantity-border);
        padding: 6px;
        @include sm {
          width: 124px;
          align-self: center; }

        .tito-quantity-input {

          box-shadow: none;
          height: 32px;
          color: var(--checkout-card-text-color, #fff);
          flex: 1 1 auto;
          width: auto !important;
          min-width: 1px;
          line-height: 1 !important;
          font-size: 24px;
          font-family: var(--checkout-form-ff);
          background: transparent;
          border: none;
          @include sm {
            height: 28px;
            font-size: 20px; }
          &::placeholder {
            color: var(--checkout-card-text-color, #fff); }
          &:focus {
            outline: 2px solid rgba($white, 0.4); } }

        .tito-release--decrement-quantity,
        .tito-release--increment-quantity {
          & > a {
            height: 32px;
            width: 32px;
            background: var(--checkout-quantity-button-bg);
            border-radius: 50%;
            margin: 0;

            display: block;
            box-sizing: border-box;
            border: var(--checkout-quantity-button-border) !important;
            position: relative;
            @include sm {
              width: 28px;
              height: 28px; }
            svg {
              display: none; }
            &:after, &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              margin-left: -1px;
              height: 14px;
              width: 2px;
              background: var(--checkout-quantity-button-icon-color);
              margin-top: -7px;
              pointer-events: none; }

            &:before {
              transform: rotate(90deg); }
            &:hover, &:focus-visible {
              background: var(--checkout-quantity-button-bg-hover);
              border: var(--checkout-quantity-button-border-hover) !important;

              &:after, &:before {
                background: var(--checkout-quantity-button-icon-color-hover); } }
            &:focus-visible {
              outline: 2px solid rgba(#fff, 0.4); } }

          &:first-child > a {
            &:after {
              display: none; } } } }

      .tito-release--status {
        background: var(--checkout-section-bg);
        border: none;
        align-self: center;
        border-radius: 5px;
        margin-right: 0;
        margin-left: 0;
        letter-spacing: 0;
        color: var(--checkout-card-text-color, #fff);
        opacity: 0.8; } }

    .tito-release--sold-out {
      order: 100;

      .tito-release--title, tito-release-description--description, .tito-price,
      .tito-price-details-regular .tito-release--tax {
        opacity: 0.7; }
      .tito-release--title {
        margin-bottom: 0;
        text-decoration: line-through; }

      .tito-release-description--description {
        display: none; } }

    .tito-times {
      display: none; } }

  input[type=text] {
    height: 44px;
    border-radius: 0;
    padding: 0 20px !important;
    font-size: 14px;
    border: none;
    font-family: var(--checkout-form-ff);
    color: var(--checkout-form-color);
    box-shadow: none;
    background: var(--checkout-form-bg);
    border: var(--checkout-form-border);
    @include sm {
      padding: 0 12px !important; }
    &::placeholder {
      color: var(--checkout-form-color); }
    &:focus {
      outline: 2px solid rgba($white, 0.4); }
    &:disabled {
      background: none !important; } }

  .tito-form-actions {
    align-items: center;

    padding: 15px;
    order: 101;
    z-index: 30;
    position: sticky;
    bottom: var(--checkout-actions-sticky, 5px);
    flex-wrap: nowrap;
    gap: 10px;
    background: var(--checkout-card-bg);
    border: var(--checkout-card-border);
    border-radius: var(--checkout-card-radius, 10px);
    @include md {
      bottom: 5px; }
    @include sm {
      padding: 15px 10px; }
    .tito-submit, .tito-submit.tito-submit--discount-code {
      // right: calc((100% - 900px) / 2)
      height: 44px;
      width: 234px;
      border: var(--checkout-button-border);
      max-width: none;
      background: var(--checkout-quantity-button-bg);
      color: var(--checkout-quantity-button-icon-color);
      // position: fixed
      // bottom: 30px
      // z-index: 11
      font-family: var(--checkout-form-ff);
      padding: 0 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      font-weight: var(--checkout-button-weight);
      border-radius: 0;
      text-transform: uppercase;
      flex: none;
      &:hover, &:focus-visible {
        color: var(--checkout-quantity-button-icon-color-hover);
        background: var(--checkout-quantity-button-bg-hover);
        border: var(--checkout-button-border-hover); }

      // +md
      //   right: 20px
      @include sm {
        width: 180px; }
      @include xs {
        width: auto;
        display: flex;
        // right: 20px
 }        // left: 20px
      @include xxs {
        padding: 0 20px;
        font-size: 12px; } }

    .tito-discount {
      min-width: auto;
      margin-bottom: 0;
      flex: 1 !important; } } }

.tito-price-details-regular {
  font-size: 40px;
  line-height: 1;
  justify-content: flex-end !important;
  font-weight: var(--checkout-price-weight, 600);
  font-family: var(--checkout-price-ff);
  color: var(--checkout-card-text-color, #fff);

  @include sm {
    grid-row: auto;
    font-size: 32px;
    justify-content: center !important; }
  @include xxxs {
    font-size: 26px; }

  .tito-release--tax {
    text-align: center !important;
    margin-top: 5px;
    color: var(--checkout-card-text-color, #fff) !important;
    @include sm {
      text-align: left !important; }

    @include sm {
      .tito-release--tax {
        font-size: 9px !important; } } }
  .tito-price {
    text-align: center !important;
    @include sm {
      text-align: left !important; } } }

.tito-overlay {
  a {
    color: inherit; } }

// .tito-block._offset
//   .tito-submit, .tito-submit.tito-submit--discount-code
//     position: static

.tito-block._promocode {
  .tito-discount {
    display: block; } }

.checkout-tab {
  display: none;
  &.is-active {
    display: block; } }

.tito-locked-ticket-message {
  order: -1; }

.tito-section {
  position: relative;
  margin-top: 20px;
  margin-bottom: 5px;

  &__title {
    font-size: 24px !important; } }

@include md {
  .hidden-iphone {
    display: none !important; } }

.с-review {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.2;

  &__text {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 1.2;
    @include sm {
      font-size: 14px; }
    a {
      color: #230CEA;
      &:hover {
        text-decoration: underline; } } }
  &__author {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: max-content;
    @include sm {
      margin-top: 20px;
      gap: 10px; }
    &-img {
      width: 56px;
      height: 56px;
      @include sm {
        width: 48px;
        height: 48px; }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }
    &-content {
      max-width: 150px;
      text-align: left; }
    &-name {
      font-size: 14px;
      font-weight: 600; }

    &-company {
      font-size: 12px;
      color: #A0A0A0;

      margin-top: 5px; } } }
