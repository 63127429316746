.experience {
	margin-top: -80px;
	@include md {
		margin-top: 60px; }
	.section__col-2 {
		padding: 168px 0 385px;
		@include md {
			padding: 49px 0 602px;
			&.clear-padding-sm {
				padding-bottom: 0; } }
		@include xs {
			padding: 56px 0 612px; } }
	&__title {
		@include md {
			margin-left: -95px; }
		@include xs {
			max-width: 280px; } }
	&__tweets {
		margin-top: -330px;
		z-index: 1;
		position: relative;
		@include md {
			margin: -581px auto 0;
			max-width: 620px; } }
	&__old {
		padding: 70px 20px;
		margin: 77px auto 0;
		max-width: 770px;
		background-color: $white;
		display: flex;
		justify-content: center;
		@include md {
			margin: 18px auto 0;
			max-width: 540px;
			padding: 50px 20px; }
		@include xs {
			margin: 8px auto 0;
			padding: 30px 20px; } }
	&--inner {
		margin-top: 0;
		@include md {
			margin-top: 0; }
		.video-wrapper {
			margin-top: -200px;
			@include md {
				margin-top: 0; } }
		.section__col-2 {
			padding: 108px 0 322px; } }
	&--inner-top {
		margin-top: -190px;
		.section__col-2 {
			padding: 308px 0 322px;
			@include md {
				padding: 40px 0; }
			@include xs {
				padding: 40px 0; } } } }

.twitts-list {
	columns: 3;
	column-gap: 50px;
	@include tablet {
		column-gap: 21px; }
	@include md {
		columns: 2; }
	@include xs {
		columns: auto;
		display: flex;
		flex-direction: column;
		align-items: center; }
	&__item {
		display: inline-block;
		margin: 0 0 20px;
		width: 100%;
		@include tablet {
			margin: 0 0 10px; }
		@include xs {
			max-width: 400px;
			margin-bottom: 0; } } }
