.section {
  max-width: 1440px;
  margin: 0 auto;
  &__row {
    display: flex; }
  &__col-1 {
    width: 30.2%;
    @include md {
      width: 35.6%; }
    @include xs {
      width: 25%; }
    &.theme-bg {
      &:after {
        left: 0;
        transform: translateX(-100%); } } }
  &__col-2 {
    width: 69.8%;
    @include md {
      width: 64.4%; }
    @include xs {
      width: 75%; }
    &.theme-bg {
      &:after {
        right: 0;
        transform: translateX(100%); } } }
  &__col-3 {
    width: 21.6%;
    @include md {
      display: none; }
    &.theme-bg {
      &:after {
        left: 0;
        transform: translateX(-100%); } } }
  &__col-4 {
    padding-top: 27px;
    width: 78.4%;
    @include md {
      width: 100%;
      padding-top: 0; }
    &.theme-bg {
      &:after {
        right: 0;
        transform: translateX(100%); } } }
  &__col-5 {
    width: 58.33%;
    &.theme-bg {
      &:after {
        left: 0;
        transform: translateX(-100%); } } }
  &__col-6 {
    height: 200px;
    margin-top: -100px;
    z-index: -1;
    width: 41.67%;
    @include md {
      display: none; }
    &.theme-bg {
      &:after {
        right: 0;
        transform: translateX(100%); } } }
  &__col-1, &__col-2, &__col-3, &__col-4, &__col-5, &__col-6 {
    &.theme-bg {
      position: relative;
      &:after {
        content: '';
        background-color: $themeColor;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100vw; } } }
  &__container {
    padding: 0 100px;
    max-width: 875px;
    z-index: 1;
    position: relative;
    @include md {
      padding: 0 30px;
      max-width: 460px; }
    @include xs {
      padding: 0 20px; }
    &--l {
      max-width: 972px; }
    &--sm {
      max-width: 770px; } }

  &__title-wrap {
    position: relative; }

  &__title {
    max-width: 800px;
    margin-left: -300px;
    position: relative;
    @include md {
      max-width: 318px;
      margin-left: -191px; }
    @include xs {
      margin-left: -90px;
      max-width: 230px; } }
  &--inner {
    .section-title {
      position: relative;
      &:after {
        @include sm {
          z-index: -1;
          content: '';
          position: absolute;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          background-image: url("../img/dots-i-mob.svg");
          right: auto;
          left: -15px;
          transform: none;
          top: -20px;
          height: 32px;
          width: 32px; } }
      @include md {
        font-size: 46px;
        @include semi; }
      @include sm {
        font-size: 36px;
        &_thin-sm {
          font-size: 28px; } } }
    .section {
      &__col-2 {
        @include md {
          width: 100%; } }
      &__row {
        @include md {
          display: block; } }
      &__title {
        margin-bottom: 40px;
        @include sm {
          margin-bottom: 20px; } }
      &__right {
        position: absolute;
        height: 63px;
        width: 163px;
        background-image: url("../img/wdots.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        right: -93px;
        transform: translateX(100%);
        z-index: -1;
        top: 20px;
        @include sm {
          display: none; } }
      &__left {
        width: 140px;
        position: absolute;
        left: -61px;
        transform: translateX(-100%);
        top: 200px;
        color: $white;
        @include tablet {
          display: none; }
        p {
          font-size: 16px;
          margin-bottom: 15px;
          line-height: 1.6; }
        &:after {
          content: '';
          position: absolute;
          background-image: url("../img/dots_blue.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          height: 103px;
          width: 23px;
          left: -28px;
          top: 0;
          transform: translateX(-100%); } }
      &__title {
        &--inner {
          margin-left: -96px; }
        @include md {
          margin-left: 0; } }
      &__container {
        position: relative;
        padding: 0 30px;
        max-width: 820px;
        @include md {
          margin: 0 auto; }
        @include sm {
          padding: 0 20px; } }
      &__img {
        margin-top: 70px;
        img {
          width: 100%; }
        @include sm {
          margin-top: 20px; } }
      &__content {
        max-width: 840px;
        margin: 0 auto;
        padding: 0 40px;
        @include sm {
          padding: 0 20px; } } } } }

.section-title {
  font-size: 64px;
  @include bold;
  line-height: 1.4;
  @include md {
    font-size: 32px;
    line-height: 1.25; }
  @include xs {
    font-size: 28px;
    line-height: 1.1; }
  &.clipthru {
    position: absolute;
    top: 0;
    left: 0;
    color: $white; } }
