.s-for-boss {
  position: relative;
  padding: 172px 0;
  overflow: hidden;
  max-width: none;
  &:after, &:before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1; }

  &:after {
    top: 10%;
    left: 41px;
    width: 183px;
    height: 183px;
    background-image: url(../img/speakers-dots1.svg); }

  &:before {
    top: 46%;
    right: -50px;
    width: 203px;
    height: 223px;
    background-image: url(../img/speakers-dots.svg); }

  .container {
    max-width: 1440px;
    padding: 0; }
  p {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 500;
    font-family: Poppins-Medium, Arial, sans-serif; }

  @include md {
    padding: 70px 0;
    &:after {
      left: -100px;
      top: 20px; }
    p {
      font-size: 14px;
      line-height: 1.79; } }

  @include xs {
    padding: 50px 0;
    &:after, &:before {
      display: none; } }

  .section__title {
    font-size: 64px;
    @include bold;
    line-height: 1.4;
    margin-left: -300px;
    margin-bottom: 30px;
    max-width: 800px;
    text-align: left;
    position: relative;
    z-index: 2;

    @include md {
      font-size: 32px;
      text-align: left;
      max-width: 318px;
      margin-left: -191px; }
    @include xs {
      margin-left: -90px;
      max-width: 230px;
      font-size: 28px; } } }

.for-boss {
  width: 69.8%;
  margin-left: auto;
  padding: 0 100px;
  @include md {
    width: 64.4%;
    padding: 0 30px; }
  @include xs {
    width: 75%;
    padding: 0 20px; }
  &__desc {
    max-width: 875px;
    @include md {
      max-width: 410px; } }
  &__img {
    display: none; } }
