.sponsors {
  position: relative;

  &__anchor {
    position: absolute;
    left: 0;
    top: -100px; }

  &__title {
    margin-bottom: 40px;
    @include md {
      margin-bottom: 25px; }
    @include xs {
      margin-bottom: 28px; } }
  .text {
    @include md {
      font-size: 12px; } }
  &:after {
    content: '';
    position: absolute;
    background-image: url(../img/sponsors-dots.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 62px;
    right: 34px;
    height: 223px;
    width: 203px;
    @include tablet {
      right: -30px; }
    @include md {
      height: 145px;
      width: 132px;
      right: -82px;
      top: 54px; } }
  .section__col-2 {
    padding: 130px 0 480px;
    @include md {
      padding: 80px 0 400px; }
    @include xs {
      padding: 60px 0 400px; } }
  &__link {
    margin-top: 19px;
    @include xs {
      margin-top: 23px; }
    a {
      color: $white;
      font-size: 26px;
      line-height: 1.54;
      text-decoration: underline;
      @include medium;
      @include md {
        font-size: 20px; }
      @include xs {
        line-height: 1.1; } } }
  &__items {
    max-width: 1170px;
    margin: -400px auto 0;
    padding: 0 0 0 100px;
    z-index: 1;
    position: relative;
    @include tablet {
      max-width: 970px;
      padding: 0; }
    @include md {
      margin: -347px 20px 0;
      max-width: none;
      padding: 0; }
    @include xs {
      margin: -347px 10px 0; } } }

.sponsors-block {
  background-color: $white;
  padding: 35px 45px 25px;
  margin-bottom: 10px;
  @include md {
    padding: 25px 20px; }
  @include sm {
    padding: 15px; }

  &__title {
    @include semi;
    font-size: 36px;
    margin-bottom: 10px;
    line-height: 1.4;
    @include md {
      font-size: 28px; }
    @include sm {
      font-size: 22px; } }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -20px;
    @include sm {
      margin: 0 -10px; } }

  &__item {
    text-align: center;
    width: 100%;

    @include tablet {
      display: flex;
      justify-content: center; }

    @include sm {
      max-width: 230px; }

    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s;
      padding: 20px;
      @include sm {
        padding: 10px; }

      &:hover {
        opacity: 0.7; }

      picture {
        display: block; }

      img {
        display: block;
        max-width: 100%;
        transition: all ease 0.2s;
        transform: translateY(0);
        opacity: 1;
        height: auto; } }

    &--w-info {
      width: 100% !important;
      max-width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 1; } }
  &__content {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    line-height: 1.45;
    @include sm {
      font-size: 10px; }
    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none; } }
    p:not(:last-child) {
      margin-bottom: 10px; } }
  &__subtitle {
    font-weight: 600;
    margin-bottom: 5px; }
  &.Platinum {
    .sponsors-block__item {
      width: 50%;
      max-width: 50%;
      @include sm {
        width: 100%;
        max-width: 100%; } } }

  &.Gold {
    .sponsors-block__item {
      width: 33.33%;
      max-width: 33.3%;
      @include xs {
        width: 50%;
        max-width: 50%; } } }

  &.Silver {
    .sponsors-block__item {
      width: 25%;
      max-width: 25%;

      @include xs {
        width: 33.3%;
        max-width: 33.3%; } } }

  &.MediaPartner,
  &.Partner,
  &.TechPartner,
  &.EntertainmentPartner {

    .sponsors-block__item {
      width: 20%;
      max-width: 20%;

      @include xs {
        width: 25%;
        max-width: 25%; } } }

  &_auto {
    .sponsors-block__list {
      justify-content: space-between; }

    .sponsors-block__item {
      width: auto;

      @include sm {
        width: 25%; }
      @include xs {
        width: 33.3%; }

      img {
        max-width: 100%; }

      &:last-child {
        margin: 0 auto 0 0; } } } }
