
.schedule-btn-wrap {
  padding: clamp(100px, 5vw, 200px) 0;
  margin-top: -100px; }
.sv-title {
  color: #fff; }
.shedule-v2 {
  margin: 0;
  background: #230cea !important;
  max-width: 100%;
  padding-bottom: 0 !important;
  --textColor: #023753 !important;
  @include rmin(1024) {
    padding-top: 120px; } }

.sv-body, .sv-time-zone,
.sv-nav, .sv-nav__item:after,
.sv-wrapper:after,
.sv-wrapper:before,
.sv-body__swiper--loading:after {
  background: #F5F7F9 !important;
  opacity: 1 !important; }

.sv-body {
  padding-bottom: 40px; }

.sv-item:after {
  background: #fff !important;
  opacity: 1 !important; }

.sv-nav {
  &__item {
    color: var(--textColor) !important;
    &:before {
      background: var(--accentColor) !important;
      opacity: 1 !important; } } }

.sv-tabs__item.is-active {
  background: #200cc7 !important;
  .sv-tabs__date {
    color: #fff !important; } }

.sv-tabs__btn {
  color: #fff !important;
  .icon {
    fill: #fff !important; }
  &.is-active {
    color: #000 !important;
    .icon {
      fill: #000 !important; } } }

.sv-tabs:before {
  background: #230cea !important; }

.sv-item__body, .sv-item__speaker-location {
  color: #474e54 !important; }
