.s-free-ticket {
  .section__col-2 {
    padding: 120px 0;
    @include md {
      padding: 55px 0; } } }

.free-ticket {
  &__text {
    margin-top: 40px;
    @include sm {
      margin-top: 20px; }
    .btn {
      margin-top: 30px;
      max-width: 450px;
      width: 100%;
      @include md {
        margin: 30px 0 0; }
      @include sm {
        font-size: 12px; } } }

  &__descr {
    color: #fff;

    p {
      &:not(:first-child) {
        margin: 24px 0 0; } } }

  &__img {

    img {
      display: block;
      width: 100%;
      height: auto; } } }

.badge-img {
  position: relative;
  z-index: 1;
  max-width: 448px;
  color: $themeColor;
  @include sm {
    max-width: 234px; }
  &:after {
    content: '';
    position: absolute;
    right: calc(50% - 65px);
    bottom: calc(100% - 30px);
    height: 150px;
    width: 44px;
    background: linear-gradient(157deg, rgba(196, 196, 196, 0.00) 0%, #FFF 100%);
    z-index: -1;
    transform: skew(-32deg);
    border-radius: 0 0 2px 2px;
    @include md {
      height: 100px;
      right: calc(50% - 45px); }

    @include sm {
      width: 24px;
      height: 64px;
      right: calc(50% - 33px);
      top: -50px;
      border-radius: 0 0 1px 1px; } }

  &__card {
    position: relative;
    width: auto;
    padding: 10px 30px 28px;
    background: #fff;
    @include sm {
      padding: 10px 10px 20px; } }

  &__body {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0 0 8%; }

  &__img {
    margin: 0 20px 0 0;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 3px solid $themeColor;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;

    @include sm {
      width: 54px;
      height: 54px;
      margin: 0 12px 0 0; }

    .icon-face {
      width: 46px;
      height: 58px;
      fill: $themeColor;

      @include sm {
        width: 26px;
        height: 34px; } } }

  &__desc {

    div:first-child {
      font-size: 32px;
      @include extraBold;
      text-transform: uppercase;
      line-height: 1.1;
      margin: 0 0 2px;

      @include sm {
        font-size: 18px; } }

    div:last-child {
      font-size: 12px;
      margin: 8px 0 0;
      line-height: 0.9;

      @include sm {
        font-size: 8px;
        margin: 5px 0 0; } } }

  &__barcode {
    position: relative;
    width: 106px;
    height: 20px;
    background: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='106.5px' height='20px' viewBox='0 0 106.5 20' style='enable-background:new 0 0 106.5 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23230CEA;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M0,0h20v6.7H0L0,0z'/%3E%3Cpath class='st0' d='M0,13.3h6.7V20H0L0,13.3z'/%3E%3Cpath class='st0' d='M6.7,6.7h6.7v6.7l-6.7,0L6.7,6.7z'/%3E%3Cpath class='st0' d='M13.3,13.3l6.7,0V20h-6.7L13.3,13.3z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M27,20V0l3.5,0v20H27z'/%3E%3Cpath class='st0' d='M33.9,20V0l1.2,0v20H33.9z'/%3E%3Cpath class='st0' d='M36.2,20V0l2.3,0v20H36.2z'/%3E%3Cpath class='st0' d='M40.8,20V0L42,0v20H40.8z'/%3E%3Cpath class='st0' d='M43.1,20V0l1.2,0v20H43.1z'/%3E%3Cpath class='st0' d='M45.4,20V0l1.2,0v20H45.4z'/%3E%3Cpath class='st0' d='M47.7,20V0l3.5,0v20H47.7z'/%3E%3Cpath class='st0' d='M54.7,20V0l1.2,0v20H54.7z'/%3E%3Cpath class='st0' d='M57,20V0l2.3,0v20H57z'/%3E%3Cpath class='st0' d='M61.6,20V0l1.2,0v20H61.6z'/%3E%3Cpath class='st0' d='M63.9,20V0L65,0v20H63.9z'/%3E%3Cpath class='st0' d='M71.9,20V0l1.2,0v20H71.9z'/%3E%3Cpath class='st0' d='M74.2,20V0l3.5,0v20H74.2z'/%3E%3Cpath class='st0' d='M81.2,20V0l1.2,0v20H81.2z'/%3E%3Cpath class='st0' d='M83.5,20V0l2.3,0v20H83.5z'/%3E%3Cpath class='st0' d='M88.1,20V0l1.2,0v20H88.1z'/%3E%3Cpath class='st0' d='M90.4,20V0l1.2,0v20H90.4z'/%3E%3Cpath class='st0' d='M92.7,20V0l1.2,0v20H92.7z'/%3E%3Cpath class='st0' d='M95,20V0l3.5,0v20H95z'/%3E%3Cpath class='st0' d='M101.9,20V0l1.2,0v20H101.9z'/%3E%3Cpath class='st0' d='M104.2,20V0l2.3,0v20H104.2z'/%3E%3C/g%3E%3C/svg%3E") center center no-repeat;

    @include sm {
      width: 62px;
      height: 12px; } }

  &__stripe {
    position: absolute;
    width: 70px;
    height: 18px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    left: 50%;
    top: 24px;
    transform: translateX(-50%);
    border-radius: 12px;
    background: $themeColor;

    @include sm {
      width: 40px;
      height: 12px;
      top: 12px; }

    &:before {
      content: '';
      position: absolute;
      left: -36px;
      bottom: calc(100% - 2px);
      height: 150px;
      width: 44px;
      background: linear-gradient(157deg, rgba(196, 196, 196, 0.00) 0%, #FFF 100%);
      transform: skew(32deg);
      border-radius: 0 0 2px 2px;
      @include md {
        height: 100px;
        left: -20px; }
      @include sm {
        width: 24px;
        height: 64px;
        left: -11px;
        border-radius: 0 0 1px 1px; } } }

  &__head {
    font-size: 21px;
    line-height: 1;
    font-weight: 600;
    height: 80px;
    width: 100%;
    color: $themeColor;
    padding: 24px 0 0;
    margin-bottom: 24px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid $themeColor;

    @include sm {
      padding: 0 5px;
      font-size: 12px;
      height: 34px;
      margin-bottom: 20px; }
    .icon {
      width: 10px;
      height: 10px;
      fill: $white;

      @include sm {
        width: 8px;
        height: 8px; } } } }
