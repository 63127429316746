.city {
  position: relative;
  padding-bottom: 30px;
  &:after {
    content: '';
    position: absolute;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../img/speakers-dots1.svg);
    height: 183px;
    width: 183px;
    left: 9px;
    top: 302px;
    @include md {
      display: none; } }
  .section__col-2 {
    padding: 180px 0 287px;
    position: relative;
    @include tablet {
      padding: 300px 0 300px; }
    @include md {
      padding: 300px 0 183px; }
    @include xs {
      padding: 200px 0 94px; } }
  .section__container {
    @include xs {
      padding: 0 25px 0 15px; } }
  &__title {
    @include bold;
    font-size: 38px;
    margin-bottom: 14px;
    @include md {
      font-size: 20px; }
    @include xs {
      line-height: 1.2;
      margin-bottom: 27px; } }
  &__slider {
    max-width: 970px;
    margin: -210px 0 0;
    z-index: 1;
    position: relative;
    overflow: hidden;
    background: #fff;
    @include md {
      margin: -130px auto 0;
      max-width: 540px; }
    @include xs {
      margin: -61px auto 0;
      .slick-list {
        padding-bottom: 60px; } } }
  &__controls {
    display: flex;
    position: absolute;
    left: 0;
    top: 195px;
    transform: translateX(-50%);
    z-index: 2;
    @include tablet {
      top: 314px; }
    @include md {
      top: 240px; }
    @include xs {
      top: 120px; }
    .slider-prev {
      margin-right: 70px;
      @include md {
        margin-right: 50px; } } } }

.cslide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 63%;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 35%;
    opacity: 0.6;
    background-image: linear-gradient(-180deg, rgba(35,12,234,0.00) 0%, #230CEA 100%); }
  &__desc {
    z-index: 1;
    position: absolute;
    bottom: 46px;
    left: 43px;
    @include medium;
    font-size: 20px;
    color: $white;
    @include md {
      font-size: 12px;
      left: 30px;
      bottom: 22px; }
    @include xs {
      left: 0;
      bottom: -42px;
      color: $textColor; } } }
