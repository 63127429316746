.welcome {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 1%;
    top: 230px;
    width: 82px;
    height: 75px;
    background-image: url(../img/welcome-dots1.svg);
    background-repeat: no-repeat;
    background-size: contain; }
  @include md {
    &:after {
      left: -17px;
      top: 148px;
      width: 42px;
      height: 42px; } }
  @include xs {
    margin-top: 30px; }
  .section__container {
    position: relative; }
  .section__col-2 {
    padding: 120px 0 180px;
    position: relative;
    @include md {
      padding: 80px 0 160px; }
    @include xs {
      padding: 60px 0 140px; } }
  &__content {
    display: flex;
    z-index: 1;
    position: relative;
    margin: 0 0 0 -320px;
    max-width: 1180px;
    gap: 60px;
    padding: 0 20px;

    @include md {
      gap: 30px; }

    @media only screen and (max-width: 860px) {
      margin: 0 0 0 -220px; }

    @include sm {
      flex-direction: column; }

    @include xs {
      margin: 0 0 0 -80px; } }

  &__left {
    flex: 1 1 auto; }
  &__right {
    flex: 1 0 330px;
    @include md {
      flex: 1 0 260px; } }

  &__title {
    margin-bottom: 21px;
    @include md {
      margin-bottom: 28px; }
    @include xs {
      margin-left: -60px; } }
  &__dots {
    position: absolute;
    top: 14px;
    right: 15px;
    @include md {
      display: none; } }
  &__addition {
    position: relative;
    font-size: 16px;
    width: 100%;
    max-width: 350px;
    margin: 0 0 72px auto;
    color: rgba(2, 55, 83, 0.9);
    line-height: 1.58;
    @include tablet {
      position: static; }
    @include md {
      max-width: 240px;
      font-size: 12px;
      line-height: 2;
      margin-bottom: 29px; }
    @include xs {
      margin-bottom: 28px;
      padding-left: 50px; } }

  &__desc {
    background: $white;
    padding: 40px;

    @include md {
      padding: 30px; } }

  &__techs-title {
    margin: 24px 0; }

  &__svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 500px;
    width: 1200px;
    transform: translateX(-42%);
    @include xs {
      height: 239px;
      width: 918px; }
    .clip-svg {
      @include coverdiv;
      .icon {
        width: 100%;
        height: 100%;
        stroke: #dddee0; }
      &.clipthru {
        .icon {
          stroke: #a79df6; } } } } }

.days-info {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__item {
    flex-wrap: wrap;
    padding: 30px;
    background: $white;

    @include md {
      padding: 20px; } }

  &__head {
    display: flex; }

  &__desc {
    font-size: 14px;
    line-height: 1.4;
    flex: 1 0;
    width: 100%;
    margin: 10px 0 0;

    @include md {
      font-size: 12px; }

    p {
      &:not(:first-child) {
        margin: 10px 0 0; } } }

  &__img {
    height: 65px;
    width: 100%;
    fill: $textColor; }

  &__title {
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    line-height: 1.2;
    margin: auto 0;

    span {
      position: relative;
      display: inline-block;
      margin: 0 7px 8px;
      top: -2px;
      font-size: 14px;
      padding: 9px 10px;
      color: $textColor;
      background: rgba(36, 252, 137, 0.15); }

    @include md {
      font-size: 18px; } }

  &__p {
    font-size: 14px;
    line-height: 1.82;
    margin: 16px 0 0;
    padding: 0; }

  &__time {
    height: 56px;
    display: flex;
    flex: 0 0 60px;
    justify-content: center;
    margin: 0 10px auto 0;
    color: $textColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    background: $heroBtnColor; }

  &__date {
    font-size: 28px;
    font-weight: 700;
    display: flex;
    line-height: 0.8;
    margin: 0;
    text-align: center;

    @include md {
      font-size: 22px; }

    em {
      white-space: nowrap;
      font-style: normal;
      font-size: 14px;
      font-weight: 400;
      margin: 0 0 0 1px;
      position: relative;
      top: -10px;

      @include md {
        font-size: 12px;
        top: -8px; } } }

  &__day {
    height: 15px;
    font-weight: 600;
    background: $textColor;
    line-height: 15px;
    color: #000;
    font-size: 12px;
    padding: 0 5px;
    width: 68px;
    text-align: center; }

  @include xs {
    padding: 0;
    margin: 0;

    &__item {
      max-width: 100%;
      text-align: left;
      padding: 15px; } } }
