 :root {

  --price-increase-bg: #fff;
  --price-increase-color: #2b3137;

  --accentColor: #230cea;

  --checkout-section-bg: #f5f7f9;
  --checkout-section-color: #023753;
  --checkout-card-border: 1px solid #d7d7d7;
  --checkout-card-bg: #fff;
  --checkout-card-radius: 0;
  --checkout-card-text-color: #000;
  --checkout-quantity-hover-color: #000;
  --checkout-quantity-border: 1px solid #d7d7d7;
  --checkout-quantity-button-bg: #230CEA;
  --checkout-quantity-button-bg-hover: #fff;
  --checkout-quantity-button-border: 2px solid #230CEA;
  --checkout-quantity-button-border-hover: 2px solid #230CEA;
  --checkout-quantity-button-icon-color: #fff;
  --checkout-quantity-button-icon-color-hover: #230CEA;
  --checkout-form-ff: GothamPro,Arial,Helvetica,sans-serif;
  --checkout-form-border: 1px solid #d7d7d7;
  --checkout-form-color: #000;
  --checkout-price-ff: Poppins-Bold,Arial,sans-serif;
  --checkout-card-title-weight: 700;
  --checkout-button-weight: 700;
  --checkout-button-border: 2px solid #230CEA;
  --checkout-button-border-hover: 2px solid #230CEA;

  --cehckout-badge-bg: #d7d7d7;
  --cehckout-badge-color: #000;

  --price-section-bg: #230cea;
  --price-color: #fff;
  --price-item-bg: #200cc7;
  --price-item-border: none;
  --price-item-radius: 8px;
  --price-item-title-color: #fff;
  --price-item-title-weight: 700;
  --price-item-date-color: $black;
  --price-item-date-size: 14px;

  --price-slider-btn-size: 54px;
  --price-slider-btn-border-color: #edb403;
  --price-slider-btn-radius: 0;
  --price-slider-btn-color: #000;
  --price-slider-btn-bg: #edb403;
  --price-slider-btn-bg-hover: #ffd121;

  --price-btn-color: #fff;
  --price-btn-border: 1px solid #FFD121;
  --price-btn-border-color-hover: #ffe88b;

  --ticketsFormBg: #200cc7;
  --ticketsFormInputBorder: 1px solid #fff;

  --workshop-item-color: #023753;
  --workshop-item-border: #DBDBDB;
  --workshop-item-border-hover: #fff;
  --workshop-item-bg: #fff;
  --workshop-item-type-bg: #230CEA;
  --workshop-item-type-color-free: #fff; }

$textColor: #023753;
$mainBg: #F5F7F9;
$themeColor: #230CEA;
$themeColorD: #230CEA;
$white: #fff;
$heroBtnColor: #FFD121;
$linkColor: #3181BC;
$gray: #999999;
$black: #000000;
$darcRed: #BC0063;
$lRed: #D00000;
$green: #36A901;
$lgreen: #00a27a;
$violet: #810CEA;
$yellow: #EDB403;
$darkBlue: #200cc7;

/*----- CHECKOUT -----*/
$checkoutSectionBg: #230CEA;
$cehckoutInputColor: #000;
$cehckoutInputBg: #fff;
$cehckoutColorGray: #eaeaec;
$cehckoutBadgeBg: #fff;
$cehckoutBadgeColor: #000;
// end CHEcKoUT

/*----- SCHEDULE -----*/
$posTop: 100px;
$scheduleColorGray: #fff;
$scheduleColorGrayL: #eaeaec;
$scheduleOutline: #8D8D8D;
// end SCHEDULE

/*----- SWITCH -----*/
$switchColor: $black;
$switchHandler: $black;

// end SWITCH
