.techs {
  display: flex;
  flex-wrap: wrap;
  margin: 32px auto 0;
  align-items: flex-start;
  justify-content: center;

  @include xs {
    margin: 32px -10px 0; }

  &__item {
    display: flex;
    justify-content: center;
    margin: 0 0 30px;
    flex-direction: column;
    padding: 0 5px;
    min-width: 80px;
    width: min-content;

    @include tablet {
      min-width: auto;
      width: 80px; }
    @include sm {
      width: 25%; }
    @include xs {
      width: 33%; }
    @include xxs {
      width: 50%; } }

  &__link {
    text-decoration: none;
    transition: color .1s;

    &:hover {
      color: #000;
      text-decoration: underline; } }

  &__img-box {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 9px;
    @include xs {
      margin: 0 0 0; }

    img {
      transform: scale(0.80);
      max-height: 50px;

      @include md {
        transform: scale(0.75); } } }

  &__title {
    display: flex;
    font-size: 12px;
    min-height: 32px;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.28; } }
