.s-sponsors-offers {
  padding: 50px 0;
  .section__title {
    @include bold;
    font-size: 64px;
    line-height: 1.4;
    margin: 38px auto 28px;
    max-width: 874px;
    text-align: center;

    @include md {
      font-size: 32px;
      margin-top: 0;
      margin-bottom: 0; }

    @include xs {
      font-size: 28px; } } }

.sponsor-banner {
  display: flex;
  margin: 68px auto 0;
  border: 4px solid #2e2e2c;
  color: #fff;
  text-decoration: none;
  padding: 14px 0;
  line-height: 1.24;
  background-color: var(--bgColor);

  &:hover {
    background-color: var(--bgColorHover);

    .sponsor-banner__cfa {
      background: #fef502;
      border-color: $white;
      color: $black; } }

  @include sm {
    flex-direction: column;
    padding: 20px;
    text-align: center;
    margin: 20px 0 10px;
    background-image: var(--logoBg);
    background-position: center;
    background-repeat: no-repeat;
    display: none; }

  &__logo-bg {
    display: flex;
    flex: 0 0 28%;
    margin: -14px 2% -14px 0;
    padding: 0 10px;
    min-height: 100%;
    background: var(--logoBg) no-repeat center;
    background-size: cover;
    justify-content: center;
    align-items: center;

    @include md {
      flex: 0 0 22%; }

    @include sm {
      padding: 0;
      margin: 0;
      background: none; } }

  &__logo {
    width: 100%;
    max-width: 200px;
    height: 100%;
    object-fit: contain;

    @include md {
      max-width: 140px; } }

  &__descr {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 1.46;
    margin: 0;

    @include md {
      font-size: 14px; }

    @include sm {
      font-size: 14px;
      margin: 16px 0; } }

  &__cfa {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 16%;
    font-size: 20px;
    margin: auto 48px auto 2%;
    padding: 0 10px;
    text-align: center;
    min-height: 60px;
    padding: 10px 10px;
    font-size: 14px;
    border: 2px solid $themeColor;
    border-radius: 5px;
    font-weight: 500;
    transition: all ease 0.2s;
    background: $mainBg;

    @include md {
      margin-right: 20px;
      font-size: 14px; }

    @include sm {
      margin: 0; } }

  & + .sponsors-offers {
    margin-top: 10px; } }

.sponsors-offers {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 68px -5px 0;

  @include md {
    margin: 20px -20px 0;
    padding: 0;
    overflow-x: auto;
    flex-wrap: nowrap;

    &:after,
    &:before {
      content: '';
      position: relative;
      display: block;
      width: 10px;
      height: 10px;
      flex: 0 0 15px; } }

  &__section {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    min-width: 0;
    flex: 1 1 auto;

    @include sm {
      margin: 0 0;
      padding: 0 0;
      flex-wrap: nowrap;
      max-width: none;
      flex: 0 0 auto;

      &:not(:first-child) {
        margin-left: 0px; } } }

  &__item {
    width: calc(25% - 20px);
    box-sizing: border-box;
    border: 4px solid $gray;
    background: $mainBg;
    padding: 0 30px 60px;
    margin: 0 10px 20px;
    display: flex;
    flex-direction: column;

    &.gold {
      width: calc(25% - 10px);
      padding: 0 20px 40px;
      margin: 0 5px 10px;

      @include tablet {
        width: calc(33.3% - 10px); }

      @include md {
        width: calc(50% - 10px);
        padding-bottom: 30px; }

      @include sm {
        width: 250px;
        padding-bottom: 20px;

        .sponsors-offers__img-wrap {
          height: 130px; } } }

    &.silver {
      width: calc(20% - 10px);
      padding: 0 20px 40px;
      margin: 0 5px 10px;

      @include tablet {
        width: calc(25% - 10px); }

      @include md {
        width: calc(33.3% - 10px);
        padding-bottom: 30px; }

      @include sm {
        width: 200px;
        padding-bottom: 20px; }

      .sponsors-offers__img-wrap {
        height: 130px; } }

    @include md {
      width: calc(33.3% - 20px);
      margin: 0 10px 20px;
      padding: 0 20px 30px; }

    @include sm {
      width: 250px;
      margin: 0 5px 10px;
      padding: 0 20px 20px; } }

  &__img-wrap {
    height: 160px;
    display: flex;
    padding: 0 0; }

  &__img {
    max-width: 100%;
    height: auto;
    max-height: 100px;
    margin: auto;
    cursor: pointer; }

  &__learn-more {
    text-align: center;
    display: none;
    button {
      appearance: none;
      border: none;
      background: none;
      text-decoration: underline;
      text-transform: uppercase;
      @include bold;
      color: $themeColor;
      &:hover {
        text-decoration: none; } } }

  &__desc {
    margin-top: 0;
    border-top: 2px solid $gray;
    font-size: 16px;
    padding: 34px 0 43px;
    line-height: 1.4;
    @include md {
      font-size: 14px; } }

  &__btn {
    @extend .btn;

    margin: 0 20px;
    margin: auto 20px 0;
    border-color: $gray;
    @include tablet {
      padding: 10px 10px; }
    @include md {
      margin: auto 0 0; } } }
