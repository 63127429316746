@include font(Poppins, Poppins-Bold, 700);
@include font(Poppins, Poppins-Light, 300);
@include font(Poppins, Poppins-Medium, 500);
@include font(Poppins, Poppins-Regular, 400);
@include font(Poppins, Poppins-SemiBold, 600);
@include font(Poppins, Poppins-Italic, 400, italic);
@include font(Poppins, Poppins-MediumItalic, 500, italic);
@include font(Poppins, Poppins-ExtraBold, 800);

@mixin extraBold {
  font-weight: 800; }

@mixin bold {
  font-weight: 700; }

@mixin italic {
  font-weight: 400;
  font-style: italic; }

@mixin mediumItalic {
  font-weight: 500;
  font-style: italic; }

@mixin light {
  font-weight: 300; }

@mixin medium {
  font-weight: 500; }

@mixin regular {
  font-weight: 400; }

@mixin semi {
  font-weight: 600; }

body {
  line-height: 1.875;
  @include regular;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  color: $textColor;
  background-color: $mainBg;
  font-size: 16px;
  @include md {
    font-size: 12px; }
  &.is-overflow, &.is-no-scroll {
    overflow: hidden; } }

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit; }

.out {
  overflow: hidden; }

.theme-bg {
  background-color: $themeColor; }

.white-color {
  color: $white;
  a {
    transition: .2s ease;
    &:hover {
      opacity: .8; } } }

.slider-btn {
  background: transparent;
  padding: 5px;
  &:hover {
    .icon {
      fill: $heroBtnColor; } }
  .icon {
    transition: 0.3s;
    fill: $white;
    font-size: 32px; } }

.slider-prev {
  .icon {
    transform: rotate(180deg);
    fill: $themeColor; } }

.inner-page {
  padding-top: 123px;
  @include md {
    padding-top: 100px; }
  &--checkout {
    padding-top: 100px;
    @include md {
      padding-top: 55px; } } }

[aria-label="popup with details about speaker"] {
    line-height: 1.32; }

.sec-checkout {
  --accentColor: #FFD121 !important;
  max-width: none !important;
  @include md {
    padding-top: 0 !important; }
  .tito-widget .tito-widget-form .tito-release:after {
    opacity: 1;
    background: #200cc7; } }

.hidden-iphone {
  @include md {
    display: none; } }

.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
  display: inline-block;
  stroke: currentColor;
  stroke-width: 0; }
