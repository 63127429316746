.fun {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    background-image: url(../img/sponsors-dots.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 50px;
    left: -40px;
    height: 223px;
    width: 203px;
    @include xs {
      height: 132px;
      width: 119px;
      top: 265px;
      left: -58px;
      transform: rotate(-90deg); } }

  .section__col-2 {
    padding: 187px 0 500px;
    position: relative;
    z-index: 1;
    @include md {
      padding: 55px 0 280px; }
    @include xs {
      padding: 55px 0 265px; }

    &.no-team {
      padding: 187px 0 150px;
      @include md {
        padding: 55px 0 55px; }
      @include xs {
        padding: 55px 0 55px; } } }
  &__title {
    margin-bottom: 43px;
    @include md {
      margin-left: -94px;
      margin-bottom: 35px;
      .section-title {
        font-size: 28px;
        line-height: 1.1; } }
    @include xs {
      margin-bottom: 27px; } }

  &__video {
    position: relative;
    padding-top: 78%;
    margin-left: -300px;
    margin-bottom: 84px;
    @include md {
      padding-top: 86%;
      margin-left: -190px;
      margin-bottom: 36px; }
    @include xs {
      margin-left: -80px;
      margin-bottom: 28px; } }
  &__video-frame {
    @include coverdiv; }
  &__items {

    z-index: 1;
    position: relative;
    display: flex;
    margin: -500px -15px 220px;
    flex-wrap: wrap;
    @include tablet {
      margin: -500px -15px 220px; }
    @include md {
      margin: -228px -15px 72px; }
    @include xs {
      margin: -215px -15px 27px; } }
  &__item {
    width: 33.33%;
    padding: 10px 15px;
    position: relative;

    @include md {
      width: 50%; }
    @include sm {
      padding: 10px 5px; }
    @include xs {
      width: 100%;
      padding: 5px; }
    &:nth-child(3n-1) {
      top: 50px;
      @include md {
        top: 0; } }
    &:nth-child(3n-2) {
      top: 110px;
      @include md {
        top: 0; } }
    &:nth-child(2n-1) {
      @include md {
        top: 50px; }
      @include xs {
        top: 0; } } } }
