.features {
	position: relative;
	padding: clamp(50px, 5vw, 100px) 0 0;
	background: $themeColor;
	max-width: none;
	color: $white;

	.section__title {
		font-size: 64px;
		@include bold;
		line-height: 1.2;
		margin: 38px auto 38px;
		max-width: 874px;
		text-align: center;

		@include md {
			font-size: 28px;
			margin-top: 0;
			margin-bottom: 25px; }

		@include xs {
			font-size: 24px; } }

	.container {
		padding: 0;
		max-width: 1180px; }

	&__btn {
		.btn {
			max-width: 222px;
			display: block;
			margin: 0 auto; } }

	&.with-btns {
		padding-bottom: 112px;

		@include laptop {
			padding-bottom: 80px; }
		@include tablet {
			padding-bottom: 72px; }
		@include md {
			padding-bottom: 103px; }
		@include sm {
			padding-bottom: 40px; } } }

.features-block {
	color: $white;
	display: flex;
	margin: 64px 0 0;
	gap: 20px;

	@include tablet {
		margin: 50px 0 0; }
	@include md {
		margin: 30px 0 0; }
	@include sm {
		gap: 10px; }
	@include xs {
		gap: 20px;
		padding: 0 10px;
		flex-direction: column; }

	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 1 1;
		padding: 30px 30px;
		min-height: 550px;
		min-width: 0;
		@include laptop {
			padding: 3vw 3.2vw;
			min-height: 44vw; }
		@include xs {
			padding: 6vw 6vw;
			min-height: 70vw;
			max-width: 380px;
			width: 100%;
			margin: 0 auto; }
		@include xxs {
			min-height: 110vw; } }

	&__title {
		position: relative;
		font-size: 26px;
		margin: auto 0 15px;
		line-height: 1.2;
		z-index: 1;
		font-weight: 600;

		@include sm {
			font-size: 20px; }

		&_small {
			font-size: 50px;

			@include laptop {
				font-size: 3.5vw; } } }

	&__desc {
		position: relative;
		font-size: 18px;
		margin: 0;
		line-height: 1.4;
		z-index: 1;

		@include sm {
			font-size: 16px; } }

	&__links {
		font-size: 20px;
		position: relative;
		z-index: 1;

		@include laptop {
			font-size: 1.8vw; }

		@include xs {
			font-size: 14px; } }

	&__link {
		color: #fff;
		margin: 2px 0;
		display: inline-block;
		text-decoration: underline;

		&:hover {
			text-decoration: none; } }

	&__back {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 0;
		overflow: hidden;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			background: linear-gradient(0deg, rgba(32,12,199,0.9) 0%, rgba(255,255,255,0) 66%); } }

	&__back-pic {
		width: 100%;
		height: 100%;
		display: block;
		object-fit: cover; } }


.networkers {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 1;
	background: $darkBlue;

	&__item {
		position: relative;
		width: 33.3%;
		height: calc(550px / 4);
		@include laptop {
			height: calc(44vw / 4); }
		@include xs {
			width: 25%;
			height: calc(70vw / 3); }
		@include xxs {
			width: 33.3%;
			height: calc(110vw / 3); } }

	&__speaker {
		width: 100%;
		height: 100%;
		display: block;
		object-fit: cover; }

	&__time {
		position: absolute;
		bottom: 8px;
		right: 8px; }

	&__face-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		background: $darkBlue; }

	&__face {
		position: relative;
		display: block;
		height: 70%;
		width: 100%;
		fill: $themeColor; } }


.features-btns {
	margin: 64px 0 0;

	@include tablet {
		margin: 50px 0 0; }
	@include md {
		margin: 30px 0 0; } }
